import React from "react";
import Item from "../ProductGallery/Gallery/Item";
import Button from "@material-ui/core/Button";
import ChevronRight from "@material-ui/icons/ChevronRight";
import "./style.styl";


export default function ItemGallery(props) {
    let {items, itemId, columns, isMobile, isScrollable, mobileWidth, negativeShift, label, loading, hideHeader, loadingItemsCount, moreButtonText, isMobileVertical, labelClassName, hideOnMore, onMore, itemComponent, className, location, moreComponent} = props;


    if (loading && items.length === 0) {
        const itemsNew = [];
        for (let i = 0; i < (loadingItemsCount || 12); i++) {
            itemsNew.push({});
        }
        items = itemsNew;
    }

    return (
        <div className="u-relative item-gallery-widget">
            <div className={"item-gallery-header u-height50 u-relative u-flexCenter " + ((!label && !onMore) || hideHeader ? "u-hide " : "") + (label ? (isMobile ? "" : "u-marginBottom25") : "")}>
                {
                    label ?
                        <div className={" label-container u-textColorNormal " + (isMobile ? "u-fontSize16 " : "u-sizeFullWidth u-flexCenter u-justifyContentCenter u-fontSize20 ") + labelClassName}>
                            <div className="label">{label}</div>
                        </div>
                        :
                        null
                }
                {
                    onMore ?
                        (
                            moreComponent ?
                                <div className="u-absolute u-right0" onClick={onMore}>
                                    {moreComponent}
                                </div>
                                :
                                <Button color="primary" onClick={onMore} className="u-absolute u-right0">{moreButtonText || "more"}<ChevronRight/></Button>
                        )
                        :
                        null
                }
            </div>
            {
                isScrollable && !isMobile ?
                    <div className="box-shadow right">

                    </div>
                    :
                    null
            }
            {
                isScrollable && !isMobile ?
                    <div className="box-shadows left">

                    </div>
                    :
                    null
            }
            <div className={" " + ((isMobile && !isMobileVertical) ? "u-overflowX " : (isScrollable ? "u-overflowX" : ""))} style={isMobile && !isMobileVertical ? {marginLeft: (negativeShift || 15) * -1, marginRight: (negativeShift || 15) * -1} : {}}>
                <div className={"item-gallery-container " + ((isMobile && !isMobileVertical) ? "u-sizeFullWidth u-flex u-paddingLeft10" : (isScrollable ? "u-flex u-marginLeftNegative10 u-marginRightNegative10 u-marginTopNegative10" : "u-flex u-flexWrap u-marginLeftNegative10 u-marginRightNegative10 u-marginTopNegative10")) + " " + (className || "")}>
                    {
                        items.map((item, i) => {
                            const width = isMobileVertical ? "100%" : (isMobile ? mobileWidth || 220 : (100 / (columns || 4)).toString() + "%");
                            if (itemComponent) {
                                return (
                                    <div    key={i}
                                            className={isMobile ? "" : "u-padding10"}
                                            style={{width: width, minWidth: width}}>
                                            {itemComponent({item, props: {...props}})}
                                    </div>
                                    );
                            }
                            return (
                                    <div    key={i}
                                            className={isMobile ? "" : "u-padding10"}
                                            style={{width: width, minWidth: width}}>
                                        <Item {...props} item={item} itemIdValue={item[itemId]}/>
                                    </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
}
