import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./style.styl";


export default function Loading(props){
    const {className, style, color} = props;
    return (
        <div className={"loading-box" + " " + (className || "")} style={style}>
            <CircularProgress style={{color: color || "#f50057"}}/>
        </div>
    );
}
